import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import axios from "axios"
import { Link } from "gatsby"
import React, { useState, useEffect, useMemo, useRef } from "react"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import LoadingModal from "../Modal/LoadingModal"
import { loginRequest } from "../Security/authConfig"
import { authToken } from "../Security/authToken"
import Seo from "../seo"
import Notifications from "../Styling/Notifications"
import { ErrorComponent } from "../Utils/ErrorComponent"
import { GetProfile } from "../Utils/ReactQueries"
import RepresentativeButton from "./RepresentativeButton"
import AboutMembership from "../Utils/AboutMembership"
import NotificationModal from "../Modal/NotificationModal"
import { Notification } from "../Styling/Notifications"
const queryClient = new QueryClient()

const WelcomeContent = () => {
  const [loading, setLoading] = useState(false)
  const { instance, accounts, inProgress } = useMsal()

  const myProfile = GetProfile(accounts, inProgress, instance)

  const GetNotifications = (account, inProgress, instance) =>
    useQuery(
      "myNotifications",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        const token = await authToken(account, inProgress, instance)
        if (token.length > 0) {
          return axios
            .get(
              process.env.GATSBY_APP_WEBAPI + "HefNotifications/Notifications",
              {
                headers: {
                  "Conent-Type": "application/json",
                  "Ocp-Apim-Subscription-Key":
                    process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                  Authorization:
                    "Bearer " +
                    (await authToken(account, inProgress, instance)),
                },
              }
            )
            .then(res => res.data)
        }
      },
      {
        refetchOnWindowFocus: false,
        retry: 3,
      }
    )

  const MyNotifications = GetNotifications(accounts, inProgress, instance)
  let firstNotification
  if (MyNotifications?.data?.length > 0) {
    firstNotification = MyNotifications?.data?.find(x =>
      x.notification?.visibility?.includes("292460000")
    )
  }

  useEffect(() => {
    if (
      (myProfile.isSuccess && myProfile?.data == null) ||
      (MyNotifications.isSuccess && MyNotifications?.data == null)
    ) {
      myProfile.refetch()
      MyNotifications.refetch()
    } else {
      setLoading(false)
      firstNotification = MyNotifications?.data?.find(x =>
        x.notification?.visibility?.includes("292460000")
      )
    }
  }, [myProfile.isSuccess, MyNotifications.isSuccess])

  return (
    <div>
      {loading ? (
        <LoadingModal />
      ) : (
        <div>
          <div className="px-8 lg:px-32">
            <div className=" border-b-2 border-blue pt-2 mb-0 lg:flex">
              <Link
                to="/"
                className="flex flex-row items-center font-sans text-blue text-sm mb-2 hover:text-orange-dark hover:cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                Min side
              </Link>
            </div>
          </div>
          <div className="bg-gray px-8 py-8 pb-16  lg:flex lg:justify-center lg:items-center">
            <div className="lg:min-w-[50%] lg:max-w-[50%]">
              <div className="lg:min-w-[50%] lg:max-w-[50%]">
                <Seo title="MinSide" />
              </div>
              <h1
                className="text-xl font-serif text-blue justify-self-center mb-12"
                id="welcome-to-hef"
                aria-labelledby="welcome-to-hef"
              >
                Velkommen til <br /> Human-Etisk forbund
              </h1>
              <p className="font-sans text-base">
                Takk for din registrering. Du vil få en bekreftelse på e-post.
              </p>
              <div>
                <Notification
                  personalNotification={firstNotification}
                  invertedNotification={false}
                />
              </div>
              <div>
                <AboutMembership />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const Welcome = () => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      // loadingComponent={LoadingModal}
    >
      <QueryClientProvider client={queryClient}>
        <WelcomeContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default Welcome
