import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import axios from "axios"
import { Link } from "gatsby"
import React, { useState, useEffect, useMemo, useRef } from "react"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import LoadingModal from "../Modal/LoadingModal"
import { loginRequest } from "../Security/authConfig"
import { authToken } from "../Security/authToken"
import Seo from "../seo"
import Notifications from "../Styling/Notifications"
import { ErrorComponent } from "../Utils/ErrorComponent"
import { GetProfile } from "../Utils/ReactQueries"
import { SeePosts } from "../MyPage/MyPosts"

const queryClient = new QueryClient()

const AboutMembership = () => {
  const [loading, setLoading] = useState(false)
  const { instance, accounts, inProgress } = useMsal()
  const [myActivePosts, setMyActivePosts] = useState([])

  const myProfile = GetProfile(accounts, inProgress, instance)

  const GetMyPosts = (account, inProgress, instance) =>
    useQuery(
      "getMyPosts",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefCommittee/me", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => {
            const tempActive = res.data?.filter(item => item.statusCode === 1)
            setMyActivePosts(tempActive)
          })
      },
      {
        refetchOnWindowFocus: false,
        retry: (count, error) => {
          if (count >= 3) {
            return false
          }
          return true
        },
      }
    )

  GetMyPosts(accounts, inProgress, instance)

  useEffect(() => {
    if (myProfile.isSuccess && myProfile?.data == null) {
      myProfile.refetch()
    } else {
      setLoading(false)
    }
  }, [myProfile.isSuccess])

  return (
    <div>
      <div className="bg-gray  py-8 pb-16  lg:justify-center lg:items-center">
        <h2
          className="text-m font-serif text-blue justify-self-center mb-4"
          id="welcome-to-hef"
          aria-labelledby="welcome-to-hef"
        >
          Om medlemskapet ditt
        </h2>
        <div className="border-b-2 border-blue border-t-2 pb-4 pt-4 space-y-4">
          <div className="flex space-x-2 ">
            <div className="items-center">
              <svg
                width="29"
                height="44"
                viewBox="0 0 29 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.1585 3.48406C24.0145 3.41206 23.9065 3.30406 23.7985 3.23206V0.92806C23.7985 0.74806 23.6185 0.60406 23.4385 0.60406H2.91845C1.33445 0.60406 0.614453 1.18006 0.614453 2.47606V28.8281C0.614453 30.7001 1.69445 31.4561 3.53045 31.4561H25.0585C25.2385 31.4561 25.3825 31.3121 25.3825 31.1321V3.95206C25.3825 3.70006 25.2385 3.48406 25.0585 3.48406H24.1585ZM3.20645 3.48406C1.91045 3.48406 1.51445 3.08806 1.51445 2.47606C1.51445 1.82806 1.98245 1.54006 2.91845 1.54006H22.8265C22.6105 2.18806 22.7905 2.98006 23.5465 3.48406H3.20645ZM3.53045 30.5201C2.27045 30.5201 1.51445 30.0881 1.51445 28.8281V4.02406C1.91045 4.24006 2.45045 4.34806 3.17045 4.34806H4.64645V30.5201H3.53045ZM8.42645 15.3641V9.20806H21.6025V15.3641H8.42645Z"
                  fill="#0F0F14"
                />
              </svg>
            </div>
            <div className="font-sans text-xs items-center">
              <p className="mb-0  font-bold"> Medlemskostnader:</p>
              <p className="mb-0">
                Gratis -{" "}
                <Link to="/giveravtale" className="underline text text-blue">
                  Start en giveravtale
                </Link>
              </p>
            </div>
          </div>
          <div className="flex space-x-2 ">
            <div className="items-center">
              <svg
                width="29"
                height="44"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.5824 16.7681L10.5984 0.71206C10.3464 0.532061 10.2744 0.496059 10.1304 0.532061L6.63844 1.28806C6.31444 1.36006 6.20644 1.43206 6.02644 1.68406L1.85044 5.86006C1.70644 6.00406 1.56244 6.11206 1.45444 6.47206L0.734438 9.92806C0.698438 10.1081 0.698438 10.2161 0.878438 10.4321L16.9344 26.4521L28.2744 29.1161C28.7424 29.1881 28.9224 29.1881 29.1744 28.9721C29.3544 28.7561 29.3544 28.5401 29.2464 28.0721L26.5824 16.7681ZM23.2344 17.6321L7.86244 2.36806L9.87844 1.90006L25.2504 17.2721L23.2344 17.6321ZM22.2624 18.6401L21.7584 21.5201L18.8064 22.0961L3.11044 6.40006L6.56644 2.94406L22.2624 18.6401ZM2.53444 7.69606L17.7984 23.0681L17.4384 25.0841L2.03044 9.67606L2.53444 7.69606ZM25.6104 25.4441C25.1064 25.8761 24.8904 26.4881 24.8184 26.9561L18.5904 25.6241L19.0584 23.2121L22.1184 22.6721C22.3704 22.6361 22.4424 22.5641 22.6584 22.3841C22.7304 22.2761 22.8024 22.0961 22.8384 21.9521L23.3424 18.9281L25.7904 18.4241L27.1584 24.6161C26.5464 24.8321 26.0424 25.1201 25.6104 25.4441Z"
                  fill="#0F0F14"
                />
              </svg>
            </div>
            <div className="font-sans text-xs items-center">
              <p className="mb-0  font-bold"> Dine verv:</p>
              <div>
                {myActivePosts.length > 0 ? (
                  <SeePosts posts={myActivePosts} />
                ) : null}
              </div>
              <p className="mb-0">
                <a
                  href="https://www.human.no/bli-frivillig"
                  target="_blank"
                  className="underline text text-blue"
                >
                  Les mer om å bli frivillig
                </a>
              </p>
            </div>
          </div>
          <div className="flex space-x-2 ">
            <div className="items-center">
              <svg
                width="29"
                height="44"
                viewBox="0 0 10 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.7381 18.6296C9.67815 15.3113 9.61274 11.544 9.36201 5.83396C9.36201 5.33426 9.25844 5.0816 9.11672 5.0816C8.975 5.0816 8.86598 5.4634 8.86598 5.83396C8.68611 15.856 6.68568 17.9614 5.05045 17.9614C2.73933 17.9614 1.49656 13.9021 1.34394 5.8901C1.34394 5.8901 1.34394 5.08721 1.34394 4.17765V3.57129C1.34394 1.63988 1.34394 1.24685 1.34394 1.24124C1.34394 0.870675 1.26763 0.494501 1.0823 0.488887C0.896974 0.483272 0.831564 0.898748 0.815212 1.24124C0.815212 1.24124 0.651689 5.86203 0.651689 5.8901C0.411856 11.3418 0.357348 14.9969 0.308291 18.2422V18.3039C0.308291 19.3538 0.275587 20.342 0.253784 21.3133C0.253784 22.1274 0.286489 22.9584 0.302841 23.8343V23.9522C0.362799 27.276 0.428208 31.0434 0.678943 36.7646C0.678943 37.2643 0.782507 37.5113 0.929677 37.5113C1.07685 37.5113 1.17496 37.1352 1.17496 36.759C1.35484 26.737 3.35527 24.6371 4.99049 24.6371C6.62572 24.6371 8.64795 26.737 8.80603 36.759C8.80603 37.1352 8.88234 37.5113 9.05676 37.5113C9.19848 37.5113 9.28024 37.2643 9.30205 36.7646C9.55278 31.049 9.61819 27.2872 9.67815 23.9634C9.67815 23.037 9.71085 22.1723 9.72721 21.3133C9.74356 20.4543 9.75446 19.5503 9.7381 18.6296ZM5.0123 15.783C6.04794 15.783 6.88736 14.6601 6.88736 13.2564C6.88736 11.8528 6.04794 10.7299 5.0123 10.7299C3.97665 10.7299 3.13179 11.8528 3.13179 13.2564C3.13179 14.6601 3.99846 15.783 5.0123 15.783Z"
                  fill="#0F0F14"
                />
              </svg>
            </div>
            <div className="font-sans text-xs items-center">
              <p className="mb-0  font-bold"> Medlemsnummeret ditt:</p>
              <p className="mb-0">{myProfile?.data?.customerNumber}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutMembership
