import * as React from "react"
import UserInfo, { UserProfileContent } from "../components/MyPage/UserInfo"
import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { loginRequest } from "../components/Security/authConfig"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import { ErrorComponent } from "../components/Utils/ErrorComponent"
import LoadingModal from "../components/Modal/LoadingModal"
import WelcomeContent from "../components/MyPage/Welcome"
import { QueryClient, QueryClientProvider } from "react-query"
import Welcome from "../components/MyPage/Welcome"
const queryClient = new QueryClient()


const WelcomePage = () => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
    // loadingComponent={LoadingModal}
    >
      <QueryClientProvider client={queryClient}>
        <Welcome />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default WelcomePage
